import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import {
  Flex,
  WhiteSection,
  LightBlueSection,
  WhiteBox,
  HeaderBanner,
  GreenBarSection,
  ButtonGreen,
  Text,
  ButtonLinkOutline,
  FooterBanner,
  BulletPoint,
  Images,
  Vis,
  MOBILE_ONLY_CSS_WRAPPER,
  TABLET_ONLY_CSS_WRAPPER,
  DESKTOP_ONLY_CSS_WRAPPER,
  LightBlueSectionNoMargin
} from "../components/common"
import { navTo } from "../utils/navigation"
import screenListen from '../utils/screenListener'

const GetInText = "Discover your data"
const GetInDescText = `Experience Australia's exciting new Consumer Data Right (CDR) and access your data faster and easier than ever before`
const bulletPoint = [
  "This is a secure, fast and free service",
  "We will not access your data without your consent",
  "We do not make copies or store your data anywhere",
  "We delete all the CDR data we collect",
  "We do not sell your data or use it for marketing",
]
const promiseText = "Our promise to you:"
const powerByText = "Powered by Australia’s leading provider of regulated data and payment solutions."
const RABText = "Regional Australia Bank"
const CustomSubtext = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 21px;
  /* or 210% */

  letter-spacing: 0.107143px;

  color: #151515;
`

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noreferrer');
};


const Spacer = styled(Flex)`
${MOBILE_ONLY_CSS_WRAPPER('margin-top:30px;margin-bottom:44px;')}
${TABLET_ONLY_CSS_WRAPPER('margin-top:32px;margin-bottom:60px;')}
${DESKTOP_ONLY_CSS_WRAPPER('margin-top:53px;margin-bottom:68px;')}
`
const PoweredByText = styled(Text.H3)`
${MOBILE_ONLY_CSS_WRAPPER('font-weight: 500; font-size: 24px; line-height: 25.86px;')}
${TABLET_ONLY_CSS_WRAPPER('font-weight: 500; font-size: 24px; line-height: 25.86px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-weight: 500; font-size: 24px; line-height: 25.86px;')}
`
const VisitText = styled(Text.Karbon20)`
${MOBILE_ONLY_CSS_WRAPPER('font-size: 20px; line-height: 26px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 20px; line-height: 26px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 20px; line-height: 26px;')}
`
const PowerByText = styled(Text.H5)`
${MOBILE_ONLY_CSS_WRAPPER(`/* Mobile/ H3 */

font-family: Arial;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 26px;
text-align: center;

color: #373737;`)}
${TABLET_ONLY_CSS_WRAPPER(`/* Mobile/ H3 */

font-family: Arial;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 26px;

color: #373737;`)}
${DESKTOP_ONLY_CSS_WRAPPER(`/* Mobile/ H3 */

font-family: Arial;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 29px;

color: #373737;`)}
`

const ButtonDelay = () => {
  setTimeout(() => navTo.howitworks_2(), 300)
}

const WhiteSectionMobile = () => {
  const { width } = screenListen()
  let baseWidth = 100
  if (width < 768 && width) {
    const scale = width * 0.05
    baseWidth = baseWidth - scale
  }
  return (
    <WhiteSection flex="col">
      <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{GetInText}</Text.H1>
      <Images.IntroductionHero style={{ alignSelf: 'center', height: 'auto', width: `${baseWidth}%` }} />
      <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>{GetInDescText}</GreenBarSection>
      <ButtonGreen onClick={ButtonDelay}>
        Get started
      </ButtonGreen>
    </WhiteSection>
  )
}

const WhiteSectionTablet = () => {
  return (
    <WhiteSection flex="col" style={{ margin: '0px 35px 0px 38px' }}>
      <Text.H1 styele={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{GetInText}</Text.H1>
      <GreenBarSection style={{ maxWidth: "488px", color: '#373737', fontFamily: 'Arial' }}>
        {GetInDescText}
      </GreenBarSection>
      <Images.IntroductionHero />
      <ButtonGreen
        style={{ width: "320px", alignSelf: "center", marginTop: "30px", flex: '0 0 auto' }}
        onClick={ButtonDelay}
      >
        Get started
      </ButtonGreen>
    </WhiteSection>
  )
}

const WhiteSectionDesktop = () => {
  let baseMarginTop = 90
  return (
    <WhiteSection flex="row" style={{ margin: '52px 50px 0px 100px' }}>
      <WhiteSection flex="col w50" style={{ padding: '0px', width: '75%' }}>
        <WhiteSection flex="col nogrow">
          <Text.H1 style={{ maxWidth: '601px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{GetInText}</Text.H1>
          <GreenBarSection style={{ overflowWrap: 'break-word', width: '380px', color: '#373737', fontFamily: 'Arial' }}>
            {GetInDescText}
          </GreenBarSection>
        </WhiteSection>
        <WhiteSection flex="row" style={{ marginTop: `${baseMarginTop}px`, marginLeft: '20px', padding: "0px 0px", width: '424px' }}>
          <ButtonGreen
            style={{ width: "320px", flex: '0 0 auto' }}
            onClick={ButtonDelay}>
            Get started
          </ButtonGreen>
        </WhiteSection>
      </WhiteSection>
      <Flex flex="w50">
        <Images.IntroductionHero />
      </Flex>
    </WhiteSection>
  )
}
const MidSectionMobile = () => {
  return (
    <LightBlueSectionNoMargin flex="col">
      <Text.H2 flex="center" style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{promiseText}</Text.H2>
      <WhiteBox flex="col shadow">
        {bulletPoint.map(text => (
          <BulletPoint key={text}>
            <div style={{ marginRight: "18px" }}>
              <Images.Tick />
            </div>
            <span style={{ color: '#373737', fontFamily: 'Arial' }}>{text}</span>
          </BulletPoint>
        ))}
      </WhiteBox>

      <PowerByText flex="center" style={{ margin: "40px 0px", color: '#373737', fontFamily: 'Arial' }}>
        {powerByText}
      </PowerByText>
      <Flex flex="center" style={{ marginBottom: '60px', padding: '0px 20px', marginTop: '20px' }}>
        <Images.CDRPrimaryRgbColour />
      </Flex>
    </LightBlueSectionNoMargin>
  )
}
const MidSectionTablet = () => {
  return (
    <LightBlueSectionNoMargin flex="col" style={{ padding: "20px 40px" }}>
      <Text.H2 flex="center" style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{promiseText}</Text.H2>
      <WhiteBox flex="col shadow" style={{ padding: '15px' }}>
        {bulletPoint.map(text => (
          <BulletPoint key={text}>
            <div style={{ marginRight: "18px" }}>
              <Images.Tick />
            </div>
            <span style={{ color: '#373737', fontFamily: 'Arial' }}>{text}</span>
          </BulletPoint>
        ))}
      </WhiteBox>

      <PowerByText flex="left" style={{ margin: "40px 10px 23px 10px", maxWidth: '250px', color: '#373737', fontFamily: 'Arial' }}>
        {powerByText}
      </PowerByText>

      <Flex>
        <Images.CDRPrimaryRgbColour style={{ maxWidth: '335px' }} />
      </Flex>
    </LightBlueSectionNoMargin>
  )
}
const MidSectionDesktop = () => {
  const { width } = screenListen()
  let basePadding = 30
  if (width > 992 && width) {
    let scale
    if (width > 1980) {
      scale = width * 0.02
    } else {
      scale = width * 0.05
    }
    basePadding = basePadding + scale
  }

  return (
    <LightBlueSectionNoMargin flex="col" style={{ padding: `120px ${basePadding}px 92px ${basePadding}px` }}>
      <Flex flex="row">
        <LightBlueSection flex="col w50 between">
          <Text.H1 flex="left" style={{ marginBottom: "40px", maxWidth: '540px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>
            {promiseText}
          </Text.H1>
          <PowerByText
            flex="left"
            style={{ marginTop: '50px', marginBottom: '-40px', textAlign: 'start', width: '440px', color: '#373737', fontFamily: 'Arial' }}
          >
            {powerByText}
          </PowerByText>

        </LightBlueSection>
        <Flex flex="col w50" style={{ paddingTop: '30px' }}>
          <WhiteBox
            flex="col nogrow shadow"
            style={{
              padding: "20px",
            }}
          >
            {bulletPoint.map(text => (
              <BulletPoint key={text}>
                <div style={{ marginRight: "18px" }}>
                  <Images.Tick />
                </div>
                <span style={{ color: '#373737', fontFamily: 'Arial' }}>{text}</span>
              </BulletPoint>
            ))}
          </WhiteBox>
        </Flex>
      </Flex>
      <Flex flex="col w50 higlight" style={{ paddingLeft: '28px', marginTop: '28px' }}>
        <Images.CDRPrimaryRgbColour style={{ marginTop: '28px', maxWidth: '355px' }} />
      </Flex>
    </LightBlueSectionNoMargin >
  )
}

// non-mobile version of footer
const FooterButtonRow = () => {
  return (
    <WhiteSection flex="row hcenter" style={{ marginTop: '68px' }}>
      <ButtonLinkOutline
        style={{ padding: "0 30px", marginRight: '21px', minWidth: "230px" }}
        rel="noreferrer noopener"
        target="_blank"
        onClick={() => openInNewTab('https://cdr.gov.au')}
        // href="https://cdr.gov.au"
      >
        LEARN MORE
      </ButtonLinkOutline>
      <ButtonLinkOutline
        style={{ padding: "0 30px", marginLeft: '21px', minWidth: "230px" }}
        rel="noreferrer noopener"
        target="_blank"
        onClick={() => openInNewTab('https://www.cuscal.com/wp-content/uploads/2024/01/Cuscal-Consumer-Data-Right-Policy.pdf')}
      >
        READ OUR CDR POLICY
      </ButtonLinkOutline>
    </WhiteSection>
  )
}

const IntroductionPage = () => {
  return (
    <Layout.ColumnLayout title={GetInText}>
      <Flex flex="center" style={{ minHeight: '55px', padding: '19px 24px', background: '#41B6E6' }}><Text.BannerText style={{ textAlign: 'center', color: '#FFFFFF' }}>🚀 Supercharge your Data Holder testing with myCDRdata <b>pro</b>! <a rel="noreferrer noopener" style={{ fontWeight: '800', color: '#FFFFFF' }}
        target="_blank" href="https://www.cuscalpayments.com.au/data/data-holder-testing-services/">Learn more</a> | <a href="/pro" style={{ fontWeight: '800', color: '#FFFFFF' }}>Sign in</a></Text.BannerText></Flex>
      <HeaderBanner hamburger={false} />
      <Vis.MobileOnly flex="col">
        <WhiteSectionMobile />
        <MidSectionMobile />
      </Vis.MobileOnly>
      <Vis.TabletOnly flex="col">
        <WhiteSectionTablet />
        <MidSectionTablet />
      </Vis.TabletOnly>
      <Vis.DesktopOnly flex="col">

        <WhiteSectionDesktop />
        <MidSectionDesktop />
      </Vis.DesktopOnly>
      <WhiteSection flex="col">
        <Text.H2 style={{ marginTop: '50px', marginBottom: "19px", alignSelf: "center", maxWidth: '872px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>
          Learn more about CDR
        </Text.H2>
        <VisitText
          style={{
            maxWidth: "559px",
            alignSelf: "center",
            color: '#373737',
            fontFamily: 'Arial'
          }}
        >
          More information about the Consumer Data Right can be found on the Australian government CDR website.
        </VisitText>
        <Vis.MobileOnly flex="col" style={{ paddingTop: '43px' }}>
          <ButtonLinkOutline
            style={{ padding: "0 30px" }}
            rel="noreferrer noopener"
            target="_blank"
            // href="https://www.cdr.gov.au"            
            onClick={() => openInNewTab('https://cdr.gov.au')}
          >
            LEARN MORE
          </ButtonLinkOutline>
          <ButtonLinkOutline
            style={{ padding: "0 30px" }}
            rel="noreferrer noopener"
            target="_blank"
            onClick={() => openInNewTab('https://www.cuscal.com/wp-content/uploads/2024/01/Cuscal-Consumer-Data-Right-Policy.pdf')}
          >
            READ OUR CDR POLICY
          </ButtonLinkOutline>
        </Vis.MobileOnly>
        <Vis.NonMobile flex="col">
          <FooterButtonRow />
        </Vis.NonMobile>
        <FooterBanner />
      </WhiteSection>
    </Layout.ColumnLayout>
  )
}

export default IntroductionPage
